import { useState, useEffect, useCallback } from 'react';

const useFetchContent = (contentUrl, handle, tokenParams) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customAttributes, setCustomAttributes] = useState(null);

  const BASE_URL = 'https://hbcms.codesncoffee.com';
  const TOKEN_URL = `${BASE_URL}/oauth/2.0/token`;

  // Define fetchData as a stable function using useCallback
  const fetchData = useCallback(async () => {
    try {
      // Step 1: Fetch the token
      const params = new URLSearchParams(tokenParams);

      const tokenResponse = await fetch(TOKEN_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
      });

      if (!tokenResponse.ok) {
        throw new Error('Failed to fetch token');
      }

      const tokenData = await tokenResponse.json();
      const token = tokenData.access_token;

      // Step 2: Fetch the content using the token
      const contentResponse = await fetch(`${BASE_URL}/${contentUrl}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': `${token}`,
        },
      });

      if (!contentResponse.ok) {
        throw new Error('Failed to fetch content');
      }

      const contentData = await contentResponse.json();
      const customAttributesData = contentData.data.custom_attributes.data;

      // Find the value based on the provided handle
      const foundAttribute = customAttributesData.find(attr => attr.handle === handle);
      const value = foundAttribute ? foundAttribute.value : null;

      // Set the custom attributes and processed data
      setCustomAttributes(customAttributesData);
      setData(
        value && /(<img[^>]*src=")\/application\/files\/([^"]*)"/.test(value)
          ? value.replaceAll(/(<img[^>]*src=")\/application\/files\/([^"]*)"/g, `$1https://hbcms.codesncoffee.com/application/files/$2"`)
          : value
      );      

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [contentUrl, handle, tokenParams]); // `fetchData` only depends on these

  // Run fetchData on mount
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data,"fetchdata-data")
  console.log(customAttributes,"fetchdata-customattr")
  return { data, error, loading, customAttributes };
};

export default useFetchContent;
