import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MyDiv, HeadingTwo, HeadingFive, PTag } from '../Common/Components';
import { Container, Row, Col } from 'react-bootstrap';
import useFetchContent from '../../hooks/useFetchContent';
import TherapistProfileCore from '../Profile/index';
import axios from "axios";

function TherapistProfilePage() {
    const { id } = useParams(); // Extract the 'id' parameter from the URL
    const [profileData, setProfileData] = useState(null); // State to store API response
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
  
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_BASE_URL+`fetchdocby/${id}`);
        //   if (!response.ok) {
        //     throw new Error(`Error: ${response.statusText}`);
        //   }
          const data = await response.data.data; // Parse JSON response
        // const data = {
        //     "doc_Contact_Info": {
        //         "address_line_one": "2220 Lakeshore Blvd",
        //         "address_line_two": "2903",
        //         "city": "Port Perry",
        //         "state": "ON",
        //         "country": "Canada",
        //         "zipcode": "M8V0C1"
        //     },
        //     "doc_SkillAndPreferences": {
        //         "preferred_working_days": [
        //             "Monday",
        //             "Tuesday",
        //             "Wednesday",
        //             "Thursday",
        //             "Friday"
        //         ],
        //         "preferred_gender": [
        //             "Female"
        //         ],
        //         "patient_type": "Couples",
        //         "skills": [
        //             "Relationship Conflicts",
        //             "Stress",
        //             "Premarital Counseling",
        //             "Infidelity and Trust Issues",
        //             "Parenting and Family Dynamics"
        //         ],
        //         "preferred_timings": [
        //             "09:00 AM - 10:00 AM",
        //             "10:00 AM - 11:00 AM",
        //             "11:00 AM - 12:00 PM",
        //             "12:00 PM - 01:00 PM",
        //             "01:00 PM - 02:00 PM",
        //             "02:00 PM - 03:00 PM",
        //             "03:00 PM - 04:00 PM"
        //         ]
        //     },
        //     "doc_Insurance_Information": {
        //         "required_liability_insurance_to_practice": "Yes",
        //         "required_inter_provincial_liability_insurance": "Yes"
        //     },
        //     "_id": "66b508373dcf770bb4bd8ef4",
        //     "doc_First_Name": "Nina",
        //     "doc_Last_Name": "Dobrev",
        //     "doc_Email": "dihon65757@advitize.com",
        //     "doc_Phone": "4376006535",
        //     "doc_Gender": "Female",
        //     "doc_DOB": "2024-08-03T00:00:00.000Z",
        //     "doc_Onboard_Status": true,
        //     "doc_Unique_ID": "100009",
        //     "doc_Profile_Image": "uploads/profilePhoto-1729015879782.jpeg",
        //     "doc_Status": "approved",
        //     "superadmin_approved": true,
        //     "doc_Work_History": [
        //         {
        //             "institution": "Mindvalley",
        //             "role": "RSW",
        //             "years_of_experience": "12",
        //             "specialization": "Couple counselling",
        //             "_id": "66b508373dcf770bb4bd8ef5"
        //         }
        //     ],
        //     "doc_Education": [
        //         {
        //             "institution": null,
        //             "degree": null,
        //             "field_of_study": null,
        //             "certificate_number": null,
        //             "certificate_issuingAuthority": null,
        //             "certificate_province": "first province",
        //             "certificate_country": null,
        //             "institution_province": null,
        //             "_id": "67160dd8e4f256674194bf7f"
        //         },
        //         {
        //             "institution": null,
        //             "degree": null,
        //             "field_of_study": null,
        //             "certificate_number": null,
        //             "certificate_issuingAuthority": null,
        //             "certificate_province": "second province",
        //             "certificate_country": null,
        //             "institution_province": null,
        //             "_id": "67160dd8e4f256674194bf80"
        //         }
        //     ],
        //     "created": "2024-08-08T18:02:31.354Z",
        //     "__v": 0,
        //     "doc_About": "Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health issues Here to help you navigate your mental health",
        //     "doc_Background": "no",
        //     "doc_SessionDuration": "one",
        //     "updated": "2024-10-21T08:16:24.496Z",
        //     "doc_CRPO": "87522512_T1",
        //     "availability": [
        //         {
        //             "day": "Monday",
        //             "_id": "6733091c8b5a1bc8a4422e27",
        //             "workingHours": []
        //         },
        //         {
        //             "day": "Tuesday",
        //             "_id": "6733091c8b5a1bc8a4422e28",
        //             "workingHours": []
        //         },
        //         {
        //             "day": "Wednesday",
        //             "_id": "6733091c8b5a1bc8a4422e29",
        //             "workingHours": []
        //         },
        //         {
        //             "day": "Thursday",
        //             "_id": "6733091c8b5a1bc8a4422e2a",
        //             "workingHours": []
        //         },
        //         {
        //             "day": "Friday",
        //             "_id": "6733091c8b5a1bc8a4422e2b",
        //             "workingHours": []
        //         }
        //     ]
        // }
          setProfileData(data); // Update state with the response
        } catch (err) {
          setError(err.message); // Handle any errors
        } finally {
          setLoading(false); // Set loading to false after API call completes
        }
      };
  
      fetchProfile();
    }, [id]); // Dependency array to re-run effect when 'id' changes
  
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    if (loading) return <div>Loading...</div>; // Display while loading
    if (error) return <div>Error: {error}</div>; // Display error message
  return (
    <React.Fragment>
      <MyDiv className="ContentPage">            
          <TherapistProfileCore profileData={profileData}/>
      </MyDiv>
    </React.Fragment>
  );
}

export default TherapistProfilePage;
