import React, { useState } from "react";
import { MyDiv } from "../Common/Components";
import { Link, useLocation } from "react-router-dom";
import { FaUserDoctor } from "react-icons/fa6";
import { RiUserHeartFill, RiDashboardFill } from "react-icons/ri";
import { FaRegFileAlt } from "react-icons/fa";
import { RiMenuUnfoldLine } from "react-icons/ri";

function SuperAdminSideBar() {
  const pathname = useLocation().pathname;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <React.Fragment>
      <MyDiv className={`LeftSideBarToggleIcon ${isSidebarOpen ? "" : "open"}`}>
        <RiMenuUnfoldLine onClick={toggleSidebar} />
      </MyDiv>
      <MyDiv className={`leftSideBarOuter ${isSidebarOpen ? "" : "open"}`}>
        <MyDiv className="SideBarMenu">
          <ul>
            <li className={pathname == "/admin" ? "menuActive" : ""}>
              <Link to="/admin">
                <RiDashboardFill />
                Dashboard
              </Link>
            </li>
            <li
              className={`${
                pathname.startsWith("/admin/specialists") ? "menuActive" : ""
              }`}
            >
              <Link to="admin/specialists">
                <FaUserDoctor /> Therapist
              </Link>
            </li>
            <li
              className={`${
                pathname.startsWith("/admin/patients") ? "menuActive" : ""
              }`}
            >
              <Link to="admin/patients">
                <RiUserHeartFill /> Clients
              </Link>
            </li>
            <li
              className={`${
                pathname.startsWith("/admin/reports") ? "menuActive" : ""
              }`}
            >
              <Link to="admin/reports">
                <FaRegFileAlt /> Report
              </Link>
            </li>
            <li
              className={`${
                pathname.startsWith("/admin/configurations") ? "menuActive" : ""
              }`}
            >
              <Link to="admin/configurations">
                <FaRegFileAlt /> Configurations
              </Link>
            </li>
          </ul>
        </MyDiv>
      </MyDiv>
    </React.Fragment>
  );
}

export default SuperAdminSideBar;
