import React from "react";
import { MyDiv } from "./Components";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { reset } from "../../Redux/userSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PAYLOADKEY = process.env.REACT_APP_PAYLOAD_SECRET_KEY;

const PAT = process.env.REACT_APP_PATIENT_ROLEID;
const SUPER = process.env.REACT_APP_SUPERADMIN_ROLEID;
const SPECIAL = process.env.REACT_APP_SPECIALIST_ROLEID;

function MainHeader() {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [expanded, setExpanded] = React.useState(false);

  const dispatch = useDispatch();

  const logoutCore = () => {
    dispatch(reset());
    
    navigate("/login");
  };

  const { firstLogin } = useSelector(state => state.user);

  const decryptData = (encryptedData, ivString) => {
    // Parse IV from hex string
    const iv = CryptoJS.enc.Hex.parse(ivString);

    // Decrypt the data using AES decryption
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(PAYLOADKEY),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)).mode;
  };

  return (
    <React.Fragment>
      <MyDiv className="MainHeader">
        <Container
          className={`${
            pathname.startsWith("/patient") ||
            pathname.startsWith("/therapist") ||
            pathname.startsWith("/admin")
              ? "fluidContainer PanelHeader"
              : null
          }`}
        >
          <Row>
            <Navbar expand="lg" expanded={expanded}>
              <Container>
                <Col md={4}>
                  <Link to="/" className="MainLogo">
                    <img
                      src={require("../../assets/images/logo-text-v24.png")}
                      alt="logo"
                    />
                  </Link>
                </Col>
                <Col
                  md={8}
                  className="d-flex justify-content-end align-items-center menu-btn"
                >
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(!expanded)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                      <ul
                        className={`${
                          pathname.startsWith("/patient") ||
                          pathname.startsWith("/therapist") ||
                          pathname.startsWith("/admin")
                            ? "hide"
                            : null
                        }`}
                      >
                        {/* <li><Link to="/" onClick={() => setExpanded(false)}>Home</Link></li> */}
                        <li
                          className={
                            pathname === "/about" ? "header-lk-active" : null
                          }
                        >
                          <Link to="/about" onClick={() => setExpanded(false)}>
                            About
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/corporate" ? "header-lk-active" : null
                          }
                        >
                          <Link to="/corporate" onClick={() => setExpanded(false)}>
                            For Corporates
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/contact" ? "header-lk-active" : null
                          }
                        >
                          <Link
                            to="/contact"
                            onClick={() => setExpanded(false)}
                          >
                            Contact
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/helpline" ? "header-lk-active" : null
                          }
                        >
                          <Link
                            to="/helpline"
                            onClick={() => setExpanded(false)}
                          >
                            Help
                          </Link>
                        </li>
                      </ul>
                    </Nav>
                    <span>
                      {user.token ? (
                        <ul>
                          {user.mode && (
                            <li className="HeaderProfileSection">
                              <MyDiv className="HeaderProfileImage">
                                <Avatar
                                  size="45"
                                  name={user.username}
                                  src={`${API_BASE_URL}${user.profilePhoto}`}
                                />
                              </MyDiv>
                              <DropdownButton title={user.username}>
                                {!firstLogin && decryptData(user.mode, user.string) ===
                                  PAT && (
                                  <>
                                    <Dropdown.Item
                                      as={Link}
                                      to="patient/dashboard"
                                    >
                                      Dashboard
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      to="patient/change-password"
                                    >
                                      Change Password
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      to="patient/profile"
                                    >
                                      My Profile
                                    </Dropdown.Item>
                                  </>
                                )}
                                
                                {!firstLogin
                                 && decryptData(user.mode, user.string) == SPECIAL && (
                                  <>
                                    {user.status ? (
                                      <>
                                        <Dropdown.Item
                                          as={Link}
                                          to="therapist"
                                        >
                                          Dashboard
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as={Link}
                                          to="therapist/change-password"
                                        >
                                          Change Password
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as={Link}
                                          to="therapist/profile"
                                        >
                                          My Profile
                                        </Dropdown.Item>
                                      </>
                                    ) : (
                                      <>
                                        <Dropdown.Item
                                          as={Link}
                                          to="therapist/onboarding"
                                        >
                                          Onboarding
                                        </Dropdown.Item>
                                      </>
                                    )}
                                  </>
                                )}
                                
                                {decryptData(user.mode, user.string) ===
                                  SUPER && (
                                  <>
                                    <Dropdown.Item as={Link} to="admin">
                                      Dashboard
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      to="admin/change-password"
                                    >
                                      Change Password
                                    </Dropdown.Item>
                                  </>
                                )}
                                <Dropdown.Item onClick={logoutCore}>
                                  Logout
                                </Dropdown.Item>
                              </DropdownButton>
                            </li>
                          )}
                        </ul>
                      ) : (
                        <ul>
                          <li className="HeaderButtonSection">
                            <DropdownButton title={"Join as"}>
                              <Dropdown.Item as={Link} to="/signup">
                                Client
                              </Dropdown.Item>
                              <Dropdown.Item as={Link} to="/therapist-signup">
                                Therapist
                              </Dropdown.Item>
                            </DropdownButton>
                          </li>
                          <li>
                            <Link to="/login" className="SignInBtn animatedBtn">
                              Sign in
                            </Link>
                          </li>
                        </ul>
                      )}
                    </span>
                  </Navbar.Collapse>
                </Col>
              </Container>
            </Navbar>
          </Row>
        </Container>
      </MyDiv>
    </React.Fragment>
  );
}

export default MainHeader;
