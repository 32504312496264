import React, { useState } from "react";
import AvailableSlots from "./AvailableSlots";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";


const TherapistProfileSlots = ({ therapistId,availability,costperhour }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  
  console.log(costperhour,"costperhour")
  const isDateAvailable = ( date ) => {
    const weekday = moment(date).format("dddd"); // Get weekday name
    const dayData = availability.find((item) => item.day === weekday); // Find matching day
    // If day doesn't exist or has no working hours, disable it
    return !dayData || dayData?.workingHours?.length === 0;
  }
  const handleDateChange = (event) => {
    const date = moment(event).format("YYYY-MM-DD");
    console.log(date,"daydate")
    setSelectedDate(date);
  };

  return (
    <>
      <Calendar
        onChange={handleDateChange} // Function to set the selected date
        value={selectedDate}
        tileDisabled={({ date }) => isDateAvailable(date)} // Disable unavailable days
        style={{width:"100%"}}
        className={"react-calendar-custom"}
      />
      {selectedDate && (
        <AvailableSlots therapistId={therapistId} date={selectedDate} costperhour={costperhour} />
      )}
    </>
  );
};

export default TherapistProfileSlots;
