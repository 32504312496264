import React, {  useState } from 'react';
import { HeadingFour, MyDiv, PTag, SpanTag } from '../Common/Components';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { RiMenuUnfoldLine } from "react-icons/ri";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function SpecialistSideBar() {
    const user = useSelector((state) => state.user);
    const pathname = useLocation().pathname;
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <React.Fragment>
             <MyDiv className={`LeftSideBarToggleIcon ${isSidebarOpen ? '' : 'open'}`} >
                <RiMenuUnfoldLine onClick={toggleSidebar} />
            </MyDiv>
            <MyDiv className={`leftSideBarOuter ${isSidebarOpen ? '' : 'open'}`}>
            <MyDiv className="SideBarProfile">
                <SpanTag className="ProfileImage">
                    <Avatar size="150" name={user.username} src={`${API_BASE_URL}${user.profilePhoto}`} />
                </SpanTag>
                <HeadingFour>{user.username}</HeadingFour>
                {user && user.age !== undefined && user.city && (
                    <PTag>
                        {/* {user.age},  */}
                    
                    {user.city}</PTag>)}
               {user.status && <Link to="/therapist/edit-profile">Edit Profile</Link>}
            </MyDiv>
            <MyDiv className="SideBarMenu">
                <ul>
                    {user.status ? 
                    <>
                    <li className={`${pathname.startsWith('/therapist/dashboard') ? "menuActive" : ""}`}>
                        <Link to='/therapist/dashboard'>Dashboard</Link>
                    </li>
                    <li className={`${pathname.startsWith('/therapist/my-appointment') ? "menuActive" : ""}`}>
                        <Link to='/therapist/my-appointment'>My Appointments</Link>
                    </li>
                    <li className={`${pathname.startsWith('/therapist/manage') ? "menuActive" : ""}`}>
                        <Link to='/therapist/manage'>Manage</Link>
                    </li>
                    </> : <li></li>}
                </ul>
            </MyDiv>
            </MyDiv>
        </React.Fragment>
    );
}

export default SpecialistSideBar;