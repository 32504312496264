import React, { useEffect, useState } from "react";
import { TimeRange } from "@matiaslgonzalez/react-timeline-range-slider";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { MyDiv, PTag } from "../Common/Components";
import { FaClock, FaDollarSign } from "react-icons/fa6";

const AvailableSlots = ({ therapistId, date, costperhour }) => {
  const [disabledIntervals, setDisabledIntervals] = useState([]);
  const [range, setRange] = useState([null, null]);
  const [timelineBounds, setTimelineBounds] = useState([null, null]);
  const [isInvalidRange, setIsInvalidRange] = useState(false);
  const [durationHours, setDurationInHours] = useState();
  const [sumcost, setSumCost] = useState();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL+"fetch-doc-availability-new/"+therapistId+"?date="+date, { headers: { 'x-access-token': user.token, 'Accept': 'application/json', 'Content-Type': 'application/json' } });
  
        const slots = response.data.data.workingHours.map((slot) => {
          const start = moment(`${date} ${slot.startTime}`, "YYYY-MM-DD HH:mm").valueOf();
          let end = moment(`${date} ${slot.endTime}`, "YYYY-MM-DD HH:mm").valueOf();
  
          // Handle cases where `endTime` is on the next day
          if (moment(slot.endTime, "HH:mm").isBefore(moment(slot.startTime, "HH:mm"))) {
            end = moment(`${date} ${slot.endTime}`, "YYYY-MM-DD HH:mm")
              .add(1, "day")
              .valueOf();
          }
  
          return { start, end };
        });
  
        // Calculate timeline bounds (therapist's working hours)
        const minTime = moment(`${date} 00:00`, "YYYY-MM-DD HH:mm").valueOf();
        const maxTime = moment(`${date} 23:59`, "YYYY-MM-DD HH:mm").valueOf();
  
        setTimelineBounds([minTime, maxTime]);
  
        // Compute unavailable intervals
        const unavailableIntervals = [];
        if (slots?.length > 0) {
          // Add interval before the first slot
          if (slots[0].start > minTime) {
            unavailableIntervals.push({ start: minTime, end: slots[0].start });
          }
  
          // Add gaps between slots
          for (let i = 0; i < slots?.length - 1; i++) {
            unavailableIntervals.push({ start: slots[i].end, end: slots[i + 1].start });
          }
  
          // Add interval after the last slot
          if (slots[slots?.length - 1].end < maxTime) {
            unavailableIntervals.push({ start: slots[slots?.length - 1].end, end: maxTime });
          }
        } else {
          // If no slots are available, mark the entire day as unavailable
          unavailableIntervals.push({ start: minTime, end: maxTime });
        }
  
        setDisabledIntervals(unavailableIntervals);
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    };
    fetchSlots();
  }, [therapistId, date]);
  
  useEffect(()=>{
    console.log(timelineBounds)
  },[timelineBounds])

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    console.log(newRange,"newRange")
    // Check if the selected range overlaps with any disabled intervals
    const isInvalid = disabledIntervals.some(
      (interval) =>
        (newRange[0] >= interval.start && newRange[0] < interval.end) ||
        (newRange[1] > interval.start && newRange[1] <= interval.end)
    );
  
    setIsInvalidRange(isInvalid);
  };

  useEffect(()=>{
    const [start, end] = range;
    const startTime = moment(start);
    const endTime = moment(end);
  
    // Calculate the difference in hours
    const durationInHours = endTime.diff(startTime, "hours", true); // Includes fractions for partial hours
    setDurationInHours(durationInHours)
    setSumCost(durationInHours * costperhour)
    console.log(costperhour,"costperhour")
        
  },[range])
  

  const handleBooking = async () => {
    try {
      await axios.post("/api/book-slot", {
        therapistId,
        date,
        startTime: moment(range[0]).format("HH:mm"),
        endTime: moment(range[1]).format("HH:mm"),
      });
      alert("Booking confirmed!");
    } catch (error) {
      console.error("Error booking slot:", error);
    }
  };
  // console.log("Transformed Timeline Bounds:", minTime, maxTime);
  
  return (
    <MyDiv>
      <br/>
      <div className={isInvalidRange ? "invalid-range-wrapper" : ""}>
      {(timelineBounds[0] && timelineBounds[1]) ? (
        <>
          <PTag><b>Choose a time</b></PTag>

          <TimeRange
            ticksNumber={30}
            onChangeCallback={handleRangeChange}
            selectedInterval={range}
            timelineInterval={timelineBounds}
            disabledIntervals={disabledIntervals}
            formatTick={(ms) => moment(ms).format("hh:mm A")} // Display time in AM/PM format
            // className={isInvalidRange ? "invalid-range" : ""} // Apply red border for invalid range
          />
        </>
      ) : 
        <PTag><b>No time slots available</b></PTag>
      }
      </div>

      {isInvalidRange && <p style={{ color: "red" }}>Selected range is unavailable.</p>}
      {durationHours ? <p><b><FaClock/> Total hours:</b> {moment(range[0]).format("hh:mm A")} - {moment(range[1]).format("hh:mm A")}, {durationHours} hours</p> : ""}
      {sumcost ? <p><b><FaDollarSign/>Total payable:</b> {sumcost}</p> : ""}<br/><br/>
      {
        (sumcost && durationHours) ?
      
      <MyDiv className="bookBtn">

      <MyDiv className="animatedBtn" onClick={handleBooking} disabled={!range[0] || !range[1]} style={{    display: "block",
    height: "50px",
    lineHeight: "50px",
    fontSize: "22px",
    borderRadius: "5px",
    textAlign: "center"}}>
        Book Now
      </MyDiv>
      </MyDiv> : ""
}
    </MyDiv>
  );
};

export default AvailableSlots;
